import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Privacidade</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Privacidade</H5>
                <ScrollAnim.Link
                  to='declaracao-de-privacidade'
                  offsetTop='120'
                >
                  Declaração de Privacidade
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='servicos-de-terceiros'
                  offsetTop='120'
                >
                  Serviços de terceiros
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='safe-harbor'
                  offsetTop='120'
                >
                  Quais dados coletamos sobre você
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='coleta-de-informacao'
                >
                  Como utilizamos seus dados
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='logs-do-servidor-web'
                  offsetTop='120'
                >
                  Com quem compartilhamos<br /> seus dados
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='informacoes-dos-usuarios'
                  offsetTop='120'
                >
                  Onde armazenamos, processamos<br /> e transferimos seus dados
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='monitoramento-e-seguranca'
                  offsetTop='120'
                >
                  Em que situações guardamos seus<br /> dados quando você pede exclusão
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='informacao-da-hostdime'
                  offsetTop='120'
                >
                  Dos seus direitos como titular<br /> dos dados
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='como-hostdime-protege'
                  offsetTop='120'
                >
                  Monitoramento e segurança
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='acesso-do-usuario'
                  offsetTop='120'
                >
                  Como a HostDime Protege<br /> sua Informação
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='transferencia-de-usuario'
                  offsetTop='120'
                >
                  Como você pode acessar e corrigir<br /> informações cobertas por esta<br /> Política de Privacidade
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='modificacao'
                  offsetTop='120'
                >
                  Como você pode transferir<br /> informações cobertas por esta<br /> Política de Privacidade a Terceiros
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='avaliacao-da-conformidade'
                  offsetTop='120'
                >
                  Como e quando esta Política<br /> de Privacidade é modificada
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='contato-com-a-hostdime'
                  offsetTop='120'
                >
                  Como a HostDime avalia a<br /> sua conformidade com a <br />política de privacidade
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='contactar-sobre-politica-de-privacidade'
                  offsetTop='120'
                >
                  Qual política adicional é<br /> aplicável a esta política
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='politica-de-seguranca-e-privacidade-da-informacao-e-servicos'
                  offsetTop='120'
                >
                  Como contactar a HostDime<br /> sobre esta Política<br /> de Privacidade
                </ScrollAnim.Link>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div>
                  <Paragraph>HostDime.com.br - Política de Privacidade (Última Rev. 28/04/2021)</Paragraph>
                  <Paragraph>A presente Política de Privacidade trata os dados pessoais dos usuários, parceiros, clientes e fornecedores, da HostDime Brasil, coletados por meio de seus sites e ferramentas web, bem como aos demais dados coletados por meio de redes sociais, entre outros. Esta política tem como propósito:</Paragraph>
                  <ul style={{ paddingLeft: 0 }}>
                    <li>&#9679; Informar quais dos seus dados pessoais coletamos, as razões pelas quais os coletamos e utilizamos, assim como com quem os compartilhamos;</li>
                    <li>&#9679; Esclarecer como utilizamos os dados pessoais compartilhados por você através de nossas plataformas web;</li>
                    <li>&#9679; Informar a você os respectivos direitos em relação aos seus dados pessoais coletados e tratados por nós, e de que forma protegemos a sua privacidade.</li>
                  </ul>
                </Div>
                <Div id='declaracao-de-privacidade'>
                  <H6 style={{ marginTop: 24 }}>1. Declaração de Privacidade da HostDime</H6>
                  <Paragraph><span>1.1 As seguintes disposições aplicam-se a todos os serviços</span></Paragraph>
                  <Paragraph>A HostDime não fornece para venda, compartilhamento ou concessão qualquer informação disponibilizada a ela por indivíduos ou entidades sujeitas a esta Política de Privacidade, exceto se estiver disposto na mesma. Se você tiver dúvidas sobre como a HostDime utiliza as informações fornecidas a ela, e essa questão não é abrangida por esta Política de Privacidade, por gentileza, entre em contato através do endereço disponibilizado no parágrafo 15 antes de fornecer qualquer informação através de outros canais.</Paragraph>
                  <Paragraph>Os serviços da HostDime são disponibilizados apenas para pessoas acima de dezoito (18) anos. A HostDime não fornece intencionalmente serviços para clientes abaixo dessa idade, assim como não coletamos deliberadamente dados pessoais de crianças e adolescentes. Se o Usuário tiver idade inferior a 18 anos, não deverá utilizar nossas plataformas e nem nos fornecer quaisquer dados pessoais.<br />Se tomarmos conhecimento de que coletamos dados pessoais de uma criança ou adolescente, tomaremos as medidas razoáveis para eliminar os dados pessoais em questão.</Paragraph>
                </Div>
                <Div id='servicos-de-terceiros'>
                  <H6 style={{ marginTop: 24 }}>2. Serviços de terceiros podem ter políticas de privacidades diferentes das políticas da Hostdime</H6>
                  <Paragraph>Esta Política de Privacidade aplica-se apenas a informações fornecidas à HostDime. Os serviços da HostDime podem incorporar serviços  fornecidos por terceiros, sendo assim, as informações fornecidas à HostDime podem ser compartilhadas com tais terceiros. No entanto, se um cliente tem a opção de escolher um produto/serviço oferecido por terceiros, ou incorporar o mesmo aos serviços da HostDime por conta própria, o cliente estará sujeito exclusivamente às políticas de privacidade do terceiro, para aquele produto/serviço. É de total obrigação do cliente, determinar como as políticas de privacidade de Produtos de Terceiros - e que não são abrangidas pela Política de Privacidade da HostDime - afetam as suas informações e, consequentemente, as informações de seus usuários finais.</Paragraph>
                </Div>
                <Div id='safe-harbor'>
                  <H6 style={{ marginTop: 24 }}>3. Quais dados coletamos sobre você</H6>
                  <ul style={{ paddingLeft: 0 }}>
                    <li><span>&#9679; Dados de cadastro na HostDime</span> - Coletamos alguns Dados sobre você quando você cria seu cadastro na HostDime. Durante o processo de cadastro nós solicitamos, para que você crie sua conta, Dados como seu nome completo, endereço de e-mail, telefone, data de nascimento, criação de uma senha, CPF/CNPJ, email e telefone secundários, CEP, endereço completo e, no caso de assinatura de Serviços, dados de cobrança e pagamento(conforme aplicável ao Serviço). Além destes dados, é possível que posteriormente sejam solicitados dados adicionais como, número e imagem do RG, CNH e CPF, estado civil, contato whatsapp, nível de instrução e escolaridade, dados de login e senha aos serviços contratados (caso seja necessário para prestarmos suporte técnico solicitado por você). Após o cadastro, você pode aproveitar as funcionalidades de sua conta HostDime.</li>
                    <li><span>&#9679; Dados de Navegação</span> - Também coletamos seus Dados quando você (i) utiliza os nossos Serviços; (ii) preenche formulários, acessa nossos sites, blog e redes sociais, entra em nosso chat, deixa feedbacks, participa de promoções, eventos online, sorteios, faz download dos nossos conteúdos, realiza buscas e demais interações nos nossos Serviços; e (iii) acessa nossos Serviços pelo seu computador, telefone celular, smart TV e/ou outro dispositivo de acesso. Os dados coletados a partir do seu acesso aos Serviços podem incluir: sua geolocalização; seu endereço de IP; informações do dispositivo de acesso (como identificador da unidade, identificador de publicidade, nome e tipo de sistema operacional); dados de login e senha de sua área de usuário/cliente CORE; informação de conexão; tipo do seu navegador e as páginas e conteúdos que você acessa em nossos Serviços, conforme definido em nossa <Link to='politica-de-cookies'>Política de Cookies</Link>.</li>
                    <li><span>&#9679; Dados para acesso às dependências da HostDime</span> - Caso o Usuário, Cliente, Visitante, Fornecedor, venha a ter acesso ao escritório e Data Center da HostDime, serão coletados pela recepção do prédio, seus dados pessoais como, nome, telefone, email, empresa, RG/CPF, assinatura e, foto, assim como a gravação das imagens por toda a infraestrutura, através do nosso circuito de câmeras de videomonitoramento, sendo tal tratamento de dados de responsabilidade exclusiva da HostDime.</li>
                    <li><span>&#9679; Dados para Recrutamento e Seleção</span> - Coletamos seus dados quando você se interessa em participar de nosso processo de recrutamento e seleção para uma de nossas vagas, divulgadas através de nossas redes sociais e do nosso site. Coletamos dados como Nome, email, telefone, assim como o currículo do candidato, o qual conterá outros dados que o próprio candidato considera importante em nos fornecer. Além das informações, caso você seja convidado a participar de entrevistas e aplicação de testes por exemplo, a HostDime tratará quaisquer dados pessoais disponibilizados por você nesses momentos. Aos candidatos selescionados para ocupar uma de nossas vagas, o tratamento de seus dados pessoais passará a ser regido pelo termo de tratamento de dados pessoais de funcionários vinculado ao contrato de trabalho.</li>
                    <li><span>&#9679; Dados obtidos por meio de prospecção de clientes</span>  - Coletamos dados como Nome, email, telefone, whatsapp, cargo e empresa, quando nossos executivos de negócios entram em contato com empresas que se interessam ou podem se interessar por nossos produtos e serviços, indicações de nossos próprios clientes, parceiros, fornecedores e funcionários, assim como para atender solicitações de orçamentos e propostas.</li>
                    <li><span>&#9679;Informações de funcionários e colaboradores de clientes</span> - A HostDime também trata informações e dados pessoais de funcionários e colaboradores dos seus clientes, fornecidos pelo próprio cliente através de seu painel CORE. São coletados Nome, CPF, RG, data de nascimento, email, telefone, whatsapp, e solicitado a criação de uma senha.</li>
                    <li><span>&#9679; Dados obtidos de terceiros</span> - Coletamos Dados sobre você de fontes disponíveis ao público, prestadores de serviços e parceiros que nos forneçam os seus dados de acordo com a política de privacidade do responsável pela coleta desses dados.</li>
                  </ul>
                </Div>
                <Div id='coleta-de-informacao'>
                  <H6 style={{ marginTop: 24 }}>4. Como a HostDime coleta informação</H6>
                  <ul style={{ paddingLeft: 0 }}>
                    <li><span>&#9679; Dados de cadastro na HostDime</span> -  Utilizamos os Dados de cadastro HostDime para permitir que você acesse seu painel de usuário CORE e gerencie sua conta, podendo contratar serviços, acessar e gerenciar serviços contratados, cadastrar contatos autorizados, atualizar suas informações, entre outras funcionalidades disponíveis em seu painel de usuário. Enviar newsletters acerca de nossos serviços e sobre a HostDime, selecionar e personalizar anúncios para você; (iii) fornecer, customizar e recomendar conteúdos e serviços; Mediante contratação de um de nossos serviços, entraremos em contato para ofertar novos serviços a você, prover suporte técnico aos serviços contratados e garantir a segurança e a funcionalidade dos mesmos, enviar ofertas, informações sobre promoções, eventos e/ou anúncios em sua conta de e-mail, sms, aplicativo de mensagens, ligação telefônica e notificação instantânea (push); nos comunicar com você e responder quando você entra em contato com a HostDime; Possibilitar que a HostDime identifique e entre em contato com você para fins de relacionamento comercial, pós venda, sucesso do cliente; Participar de nossas pesquisas de satisfação; Enviarmos brindes; Para realizar o processamento dos pagamentos efetuados por você via cartão de crédito/débito, seja de forma manual ou automática; Enviar notas fiscais dos serviços contratados, cumprir com os  <Link to='termos-de-servico'>Termos de Uso dos Serviços</Link> ou contrato particular de prestação de serviços(quando aplicável) e dar cumprimento às finalidades nele previstas.</li>
                    <li><span>&#9679; Dados de Navegação</span> - Utilizamos os Dados de navegação, desde seu primeiro acesso aos nossos serviços, para: Compreender como você utiliza nossos sites e serviços; Selecionar e personalizar anúncios para você; Fornecer, customizar e recomendar conteúdos e serviços; Ofertar novos serviços a você, inclusive aqueles cuja contratação você iniciou mas não finalizou; Avaliar o desempenho e aprimorar os Serviços; Avaliar a efetividade e alcance de anúncios e também para entender os perfis de pessoas que acessam tais anúncios; Monitorar atividades e tendências de uso; Enviar ofertas, informações sobre promoções, eventos e/ou anúncios em sua conta de e-mail, sms, aplicativo de mensagens, ligação telefônica e notificação instantânea (push) mediante preenchimento de um de nossos formulários; Nos comunicar com você e responder quando você entra em contato com a HostDime através de nossos canais de atendimento.</li>
                    <li><span>&#9679; Dados para acesso às dependências da HostDime</span> - Possibilitar que a HostDime gerencie as entradas e saídas de todos os visitantes, funcionários, clientes, fornecedores, bem como acompanhar todo o trajeto durante sua estada em nossas instalações, como forma de adequação aos termos de segurança da empresa.</li>
                    <li><span>&#9679; Dados para Recrutamento e Seleção</span> - Possibilitar que a HostDime entre em contato para fins de recrutamento e seleção para preenchimento de vagas de emprego na HostDime, assim como incluir os candidatos em seu banco de currículos para novas oportunidades.</li>
                    <li><span>&#9679; Dados obtidos por meio de prospecção de clientes</span> - Possibilitar que a HostDime entre em contato para prestar informações sobre nossos produtos e serviços, preparar e enviar orçamentos/propostas comerciais, sanar dúvidas e entender melhor as necessidades do cliente acerca das soluções personalizadas solicitadas, relacionamento comercial.</li>
                    <li><span>&#9679; Informações de funcionários e colaboradores de clientes</span> - Possibilitar que o funcionário/colaborador do cliente tenha acesso ao painel de contato autorizado, realize ações administrativas concedidas pelo cliente através do seu painel CORE, tenha permissão para agendar visitas técnicas e acessar o Data Center(Exclusivo para clientes Colocation).</li>
                    <li><span>&#9679; Dados obtidos de terceiros</span> - Utilizamos os Dados obtidos de terceiros para (i) fazer análises sobre conteúdos e temas que possam ser do seu interesse; (ii) aprimorar os nossos Serviços; (iii) personalizar o conteúdo e a publicidade que mostramos; e (iv) obter histórico de crédito para ajudar a detectar e evitar casos de fraude. Nós podemos, ainda, utilizar os Dados obtidos de terceiros para as mesmas finalidades permitidas ao terceiro, se você assim o tiver autorizado, assim como nos termos desta Política, quando você a aceitar.</li>
                  </ul>
                </Div>
                <Div id='logs-do-servidor-web'>
                  <H6 style={{ marginTop: 24 }}>5. Com quem compartilhamos seus dados</H6>
                  <Paragraph>A HostDime fica autorizada a compartilhar os seus dados pessoais com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709/18, nos termos do art. 7º, §5º da referida norma.</Paragraph>
                  <Paragraph>A HostDime fica autorizada a compartilhar os seus dados pessoais internacionalmente, com as demais unidades do grupo HostDime e nosso parceiro de CRM, caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709/18, nos termos do art. 7º, §5º da referida norma.</Paragraph>
                  <Paragraph>A HostDime utiliza serviços e tecnologias de terceiros confiáveis, que seguem padrões de segurança aceitáveis e que estão sujeitos à obrigação de confidencialidade e proteção de dados. Seus Dados serão compartilhados com tais terceiros (abaixo), sempre de acordo com essa Política, o termo de uso do respectivo Serviço (quando houver) e a legislação aplicável.</Paragraph>
                  <ul>
                    <li><span>&#9679; Provedores de Data Center parceiros</span> - Para (i) prestar serviços de Data Center para hospedar os Serviços ofertados em São Paulo e fora do Brasil. Nestas circunstâncias, seus Dados permanecem armazenados e processados pela HostDime.</li>
                    <li><span>&#9679; Prestador de Serviços de Suporte Técnico</span> - Para que terceiros que nos prestam serviços de suporte técnico forneçam assistência aos nossos clientes. Nestas circunstâncias, seus Dados permanecem armazenados e processados pela HostDime.</li>
                    <li><span>&#9679; Prestadores de Serviços de Marketing</span> - Para oferecer anúncio relevante ao seu perfil, e-mail marketing, SMS, notificação instantânea (push).</li>
                    <li><span>&#9679; Plataforma de CRM</span> - Para relacionamento comercial com nossos clientes, acompanhamento de propostas comerciais e solicitações de serviços.</li>
                    <li><span>&#9679; Instituições Financeiras, Provedores de Meios de Pagamento, Integradores de Meios de Pagamento e Empresas de Cartões de Crédito</span> - Para processar o pagamento no caso de você contratar um Serviço pago, vamos compartilhar o seu número de cartão de crédito, dados bancários, dados de cobrança e informações de contato com instituições financeiras, provedores de meios de pagamento, integradores de meios de pagamento e empresas de cartões de crédito.</li>
                    <li><span>&#9679; Autoridades Governamentais</span> - Para (i) cumprir determinações legais, judiciais e administrativas e também cumprir ofícios de autoridades competentes e (ii) tomar ou provocar medidas legais, judiciais e administrativas para defender nossos direitos previstos nesta Política, inclusive em qualquer processo judicial ou administrativo. Entende-se por Autoridades Governamentais, autoridades policiais, de proteção de dados, entidades públicas e/ou outras organizações governamentais.</li>
                    <li><span>&#9679; Outras unidades internacionais da HostDime.Inc</span> - Para que possam gerenciar os seus serviços alocados em nossos Data Centers fora do Brasil, processar e armazenar seus dados, e prestar Suporte Técnico.</li>
                    <li><span>&#9679; Parceiros de Negócios</span> - Para (i) aprimorar nossos Serviços; (ii) ampliar nossos negócios; e/ou (iii) desenvolver novos negócios.</li>
                  </ul>
                </Div>
                <Div id='informacoes-dos-usuarios'>
                  <H6 style={{ marginTop: 24 }}>6. Onde armazenamos, processamos e transferimos seus dados</H6>
                  <Paragraph>Os seus Dados poderão ser transferidos, armazenados e processados dentro e/ou fora do Brasil pela HostDime ou por nossos parceiros, de acordo com essa Política, além de seguir padrões aceitáveis de segurança e confidencialidade, para fins de prestação dos Serviços a você.</Paragraph>
                </Div>
                <Div id='monitoramento-e-seguranca'>
                  <H6 style={{ marginTop: 24 }}>7. Em que situações guardamos seus dados quando você pede exclusão</H6>
                  <Paragraph>Caso você solicite a eliminação de seus Dados, a HostDime poderá mantê-los somente pelo prazo necessário para defesa em processos judiciais, administrativos e arbitrais, (a) para cumprimento de obrigação legal, (b) para o exercício regular de direitos e (c) para fazer valer os direitos da HostDime com base no contrato de assinatura e/ou termo de uso aplicável.</Paragraph>
                </Div>
                <Div id='informacao-da-hostdime'>
                  <H6 style={{ marginTop: 24 }}>8. Dos seus direitos como titular dos dados</H6>
                  <Paragraph>Você, como titular dos dados, tem direito a obter da HostDime, em relação aos dados por ele tratados, a qualquer momento e mediante requisição: I - confirmação da existência de tratamento; II - acesso aos dados; III - correção de dados incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709/18; V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador; VI - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; VII - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709/18; VIII - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados; IX - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; X - revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709/18.</Paragraph>
                </Div>
                <Div id='como-hostdime-protege'>
                  <H6 style={{ marginTop: 24 }}>9. Monitoramento e segurança</H6>
                  <Paragraph>A HostDime leva a segurança de sua rede muito a sério. Para garantir a segurança, integridade e privacidade dos dados, a HostDime usa ferramentas que monitoram o tráfego e o acesso à rede, com o objetivo de detectar possíveis atividades ilícitas por meio da rede, que podem prejudicar os serviços e os nossos clientes. Estes programas coletam informações a respeito do uso do usuário referente aos serviços, site da HostDime e rede (Informações de Tráfego).</Paragraph>
                </Div>
                <Div id='acesso-do-usuario'>
                  <H6 style={{ marginTop: 24 }}>10. Como a HostDime Protege sua Informação</H6>
                  <Paragraph>A HostDime utiliza técnicas de segurança para evitar perda, mau uso, alteração ou divulgação não autorizada de informações sob nosso controle. Usamos medidas de segurança, incluindo mas não limitado a: firewalls, sistemas que utilizam rede segura (não pública), senhas complexas, antivírus, mitigação, 2FA e software de detecção. Quando a HostDime pede que os usuários forneçam informações financeiras (como número de cartão de crédito) os dados são protegidos usando a tecnologia Secure Sockets Layer (SSL), além de aplicar boas práticas de segurança em conformidade com o PCI-DSS . A comunicação eletrônica (e-mail, chat online, ou mensagens instantâneas) que os usuários podem enviar para a HostDime podem não ser 100% seguros, a menos que a HostDime informe com antecedência de que as medidas de segurança estarão em vigor antes da transmissão das informações por estes meios. Além disso, a HostDime possui implementado um sistema de Gestão de Segurança e Privacidade da Informação apoiado nas melhores práticas internacionais estabelecidas pela ISO 27001, ISO 27701, normas das quais a HostDime Brasil é certificada e reconhecida internacionalmente, através de auditorias independentes realizadas periodicamente por entidades certificadoras, mantendo assim um programa de segurança e privacidade da informação.</Paragraph>
                </Div>
                <Div id='transferencia-de-usuario'>
                  <H6 style={{ marginTop: 24 }}>11. Como você pode acessar e corrigir informações cobertas por esta Política de Privacidade</H6>
                  <Paragraph>Se um usuário deseja acessar e / ou atualizar as informações coletadas pela HostDime, o usuário pode acessar ou portal do cliente (CORE). Se um usuário necessitar ter uma lista de sistemas que contém informações sobre sua conta, isso também pode ser obtido entrando em contato com nossa equipe através do email privacidade@hostdime.com.br. As informações abrangidas por esta política de privacidade podem ser excluídas a pedido de um usuário. No entanto, nos reservamos o direito de reter a informação necessária para proteger nossos interesses. A menos que estabelecido aqui, as informações abrangidas por esta política de privacidade seráserão retidas pela HostDime após a cessação do acordo de usuário.</Paragraph>
                </Div>
                <Div id='modificacao'>
                  <H6 style={{ marginTop: 24 }}>12. Como você pode transferir informações cobertas por esta Política de Privacidade a Terceiros</H6>
                  <Paragraph>Os usuários podem transferir a propriedade de suas contas para terceiros através da apresentação de um pedido ao nosso Atendimento de Suporte, que disponibilizará um formulário de transferência. Uma vez que o formulário é respondido, o mesmo é verificado, e sendo autorizado a conta será transferida. O novo proprietário ficará obrigado por esta Política de Privacidade e os acordos estabelecidos no parágrafo 2.</Paragraph>
                </Div>
                <Div id='avaliacao-de-conformidade'>
                  <H6 style={{ marginTop: 24 }}>13. Como e quando esta Política de Privacidade é modificada</H6>
                  <Paragraph>A HostDime poderá modificar esta política de privacidade a qualquer momento. Quando realizarmos alterações materiais à presente Política, faremos uma comunicação visível e adequada de acordo com as circunstâncias, apresentando uma comunicação visível na Plataforma ou através do envio de um e-mail ao Usuário. Poderemos enviar antecipadamente uma comunicação a você. Assim, é fundamental que você se certifique de ler qualquer comunicação atentamente.</Paragraph>
                </Div>
                <Div id='contato-com-a-hostdime'>
                  <H6 style={{ marginTop: 24 }}>14. Como a HostDime avalia a sua conformidade com a política de privacidade</H6>
                  <Paragraph>A HostDime usa uma abordagem contínua de avaliação de conformidade, através de auditorias internas e externas independentes, para assegurar a conformidade com esta Política de Privacidade, e regularmente verifica que a política de privacidade é precisa, disponível, acessível e conforme legislações de privacidade aplicáveis.</Paragraph>
                </Div>
                <Div id='contactar-sobre-politica-de-privacidade'>
                  <H6 style={{ marginTop: 24 }}>15. Qual política adicional é aplicável a esta política</H6>
                  <Paragraph>Ao aceitar esta Política, você está igualmente sujeito à <Link to='politica-de-cookies'>Política de Cookies</Link>, a <Link to='politica-de-seguranca-e-privacidade-da-informacao-e-servicos'>Política de Segurança e Privacidade da Informação e Serviços</Link>, assim como nossos <Link to='termos-de-servico'>Termos de Uso dos Serviços</Link>.</Paragraph>
                </Div>
                <Div id='politica-de-seguranca-e-privacidade-da-informacao-e-servicos'>
                  <H6 style={{ marginTop: 24 }}>16. Como contactar a HostDime sobre esta Política de Privacidade</H6>
                  <Paragraph>Se os usuários tiverem quaisquer solicitações, perguntas, comentários ou sugestões sobre esta Política de Privacidade ou o uso e divulgação de informações pessoais, entre em contato pelo e-mail <a href='mailto:privacidade@hostdime.com.br'>privacidade@hostdime.com.br</a>, que a HostDime responderá em tempo hábil. Você pode também entrar em contato conosco por escrito, no endereço:</Paragraph>
                  <Paragraph>HostDime Brasil<br />Av. São Paulo, 1223.<br />Bairro dos Estados<br />58030-041<br />João Pessoa/PB - Brasil</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo 
        title='Política de Privacidade'
        description='' 
      />
      <PageTemplate
        title='Política de Privacidade'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
